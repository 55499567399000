import React from 'react'
import { Hidden } from '@material-ui/core'
import CarouselArrowShort from '../svg/CarouselArrowShort'
import CarouselArrow from '../svg/CarouselArrow'
import styles from '../../styles/testimonialsControlArrow.module.css'

const TestimonialControlArrow = (): JSX.Element => {
  return (
    <>
      <Hidden xsDown implementation='css'>
        <CarouselArrow className={ styles.arrowIcon }/>
      </Hidden>
      <Hidden smUp implementation='css'>
        <CarouselArrowShort className={ styles.arrowIcon }/>
      </Hidden>
    </>
  )
}

export default TestimonialControlArrow
