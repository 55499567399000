import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  color?: string;
  className?: string;
}

const CarouselArrow = ({ color = 'black', className }: Props): JSX.Element => {
  return (
    <SvgIcon width="76" height="12" viewBox="0 0 76 12" fill="none"
             className={ className }>
      <path
        d="M0.469673 6.53032C0.176773 6.23743 0.176773 5.76256 0.469673 5.46966L5.24264 0.696693C5.53554 0.4038 6.01041 0.4038 6.3033 0.696693C6.59619 0.989586 6.59619 1.46446 6.3033 1.75735L2.06066 5.99999L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53554 11.5962 5.24264 11.3033L0.469673 6.53032ZM76 6.75L1 6.74999L1 5.24999L76 5.25L76 6.75Z"
        fill={ color }/>
    </SvgIcon>
  )
}

export default CarouselArrow
