import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  color?: string;
  className?: string;
}

const CarouselArrowShort = ({ color = 'black', className }: Props): JSX.Element => {
  return (
    <SvgIcon width="22" height="9" viewBox="0 0 22 9" fill="none"
             className={ className }>
      <path
        d="M4.64501 8.94938L5.2065 8.3932C5.25754 8.34264 5.25754 8.29208 5.2065 8.24152L2.09281 5.15725C2.04176 5.10669 2.09281 4.955 2.1949 4.955L21.8979 4.955C21.949 4.955 22 4.90444 22 4.85388L22 4.09545C22 3.99433 21.949 3.94377 21.8979 3.94377L2.1949 3.94376C2.09281 3.94376 2.04176 3.84264 2.09281 3.74152L5.15545 0.707809C5.2065 0.657247 5.2065 0.606685 5.15545 0.556123L4.59397 -5.58811e-05C4.54293 -0.0506178 4.49188 -0.0506178 4.44083 -5.58945e-05L0.0510449 4.34826C4.01138e-07 4.39882 3.96718e-07 4.44938 0.0510449 4.49994L4.54292 8.94938C4.54292 8.99994 4.59397 8.99994 4.64501 8.94938Z"
        fill={ color }/>
    </SvgIcon>
  )
}

export default CarouselArrowShort
