import React from 'react'
import styles from '../../styles/FeaturedProjects.module.css'
import { SanityFeaturedProjects } from '../../../graphql-types'
import { Link } from 'gatsby'
import ForwardIcon from '../svg/ForwardIcon'
import ArrowButton from '../ArrowButton'
import { Hidden } from '@material-ui/core'
import SectionHeader from '../SectionHeader'

export interface Props {
  data: (Pick<SanityFeaturedProjects, 'name' | 'isTextDark' | 'images'> | null)[];
}

const FeaturedProjects = ({data}: Props): JSX.Element => {

  return (
    <section className={styles.container}>
      <SectionHeader className={ styles.header }>Featured
        Projects</SectionHeader>
      <div>
        {
          data.map((project) => {
            if (!project?.name) return null
            const { id, name, isTextDark, images } = project
            if (!images?.length) return null
            const color = isTextDark ? '#1E1E1E' : 'white'
            return (
              <Link to={ `/project/${ id }` } className={ styles.link }
                    key={ id }>
                <div key={ name } className={ styles.image }
                     style={ { backgroundImage: `url(${ images[0]?.asset?.url }` } }>
                  <Hidden xsDown implementation='css'>
                    <div className={ styles.projectNameContainer }>
                      <ForwardIcon arrowColor={ color } circleColor={ color }
                                   className={ styles.icon }/>
                      <p className={ styles.projectName }
                         style={ { color } }>{ name }</p>
                    </div>
                  </Hidden>
                </div>
                <Hidden smUp implementation='css'>
                  <p className={ styles.projectName }>{ name }</p>
                </Hidden>
              </Link>
            )
          })
        }
      </div>
      <div className={styles.seeMore}>
        <ArrowButton to='/work' dark>See more work</ArrowButton>
      </div>
    </section>
  )
}

export default FeaturedProjects
