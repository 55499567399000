import React from 'react'
import styles from '../../styles/WhoWeAre.module.css'
import { SanityHomePage } from '../../../graphql-types'
import ArrowButton from '../ArrowButton'
import HammerTime from '../svg/HammerTime'
import  { ScrollRotate } from 'react-scroll-rotate'
import SectionHeader from '../SectionHeader'
import { Grid } from '@material-ui/core'
import classNames from 'classnames'
export interface Props {
  data: SanityHomePage['_rawWhoWeAre'];
  image?: string | null;
}
const BlockContent = require('@sanity/block-content-to-react')

const WhoWeAre = ({data, image}: Props): JSX.Element => {
  return (
    <section className={styles.container}>
      <Grid container>
        <Grid item sm={ 6 } xs={ 12 } className={ styles.gridItem }>
          <SectionHeader dark className={ styles.title }>A little bit about
            us.</SectionHeader>
          <BlockContent blocks={ data } className={ styles.text }/>
          <div className={ styles.moreButtonContainer }>
            <ArrowButton to='/about'>Learn More</ArrowButton>
          </div>
        </Grid>
        <Grid item sm={ 6 } xs={ 12 }
              className={ classNames(styles.gridItem, styles.imageContainer) }>
          <div style={ { backgroundImage: `url(${ image })` } }
               className={ styles.image }>
            <div className={ styles.hammerTimeContainer }>
              <ScrollRotate>
                <HammerTime className={ styles.hammerTime }/>
              </ScrollRotate>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default WhoWeAre
