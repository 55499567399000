import React from 'react'
import styles from '../../styles/CarouselIndicator.module.css'
import classNames from 'classnames'

export interface Props {
  handleClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isSelected: boolean;
}

const CarouselIndicator = ({handleClick, isSelected}: Props): JSX.Element => {
  return (
    <li onClick={handleClick} className={classNames(styles.indicator, isSelected && styles.selected)} />
  )
}

export default CarouselIndicator
