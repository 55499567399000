import React from 'react';
import '../styles/index.css';
import PageLayout from "../components/PageLayout";
import { graphql } from 'gatsby'
import { HomeQuery } from '../../graphql-types'
import styles from '../styles/index.module.css'
import WorkCarousel from '../components/home/WorkCarousel'
import WhoWeAre from '../components/home/WhoWeAre'
import FeaturedProjects from '../components/home/FeaturedProjects';
import ValuePropositions from '../components/home/ValuePropositions'
import ContactForm from '../components/contactUs/ContactForm';
import Testimonials from '../components/home/Testimonials';

interface Props {
  data: HomeQuery;
}

function Index({data: {sanityHomePage, allSanityFeaturedProjects}}: Props): JSX.Element | null {
  if (!sanityHomePage) return null
  const { title, subtitle, carouselImages, _rawWhoWeAre, whoWeAreImage, _rawValuePropositions, valuePropositions, _rawTestimonials } = sanityHomePage

  return (
    <PageLayout title='FS Woodworks'>
      <h1 className={ styles.header }>{ title }</h1>
      <h4 className={ styles.subtitle }>{ subtitle }</h4>
      <WorkCarousel images={ carouselImages }/>
      <WhoWeAre data={_rawWhoWeAre} image={whoWeAreImage?.asset?.url} />
      <FeaturedProjects data={allSanityFeaturedProjects?.nodes} />
      <ValuePropositions valuePropositions={ valuePropositions }
                         _rawValuePropositions={ _rawValuePropositions }/>
      <Testimonials testimonials={ _rawTestimonials }/>
      <ContactForm/>
    </PageLayout>
  )
}

export const query = graphql`
  query home {
    sanityHomePage {
      title
      subtitle
      carouselImages {
        name
        image {
          asset {
            url
          }
        }
      }
      valuePropositions {
        icon {
          asset {
            url
          }
        }
      }
      testimonials {
        attribution
      }
      _rawTestimonials
      _rawValuePropositions(resolveReferences: {maxDepth: 2})
      _rawWhoWeAre(resolveReferences: {maxDepth: 2})
      whoWeAreImage {
        asset {
          url
        }
      }
    }
    allSanityFeaturedProjects(filter: {onHomePage: {eq: true}}) {
      nodes {
        id
        name
        isTextDark
        images {
          asset {
            url
          }
        }
      }
    }
  }
`

export default Index;
