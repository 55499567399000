import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
    color?: string;
    className?: string;
}

const StampIcon = ({ color = '#bf7530', className }: Props): JSX.Element => {
    return (
      <SvgIcon width="297.43" height="297.62" viewBox="0 0 297.43 297.62"
               fill="none" className={ className }>

          <defs>
              <style>
                  {
                      `.cls-1{fill:${ color };}.cls-2{fill:#f2ecda;}`
                  }
              </style>
          </defs>

          <path className="cls-1"
                d="M81.7,94.75,78.06,81.36l-1-2h5.59l-.13,2,2.68,10.46L87.9,81.41l-.49-2h4.94l-.51,2,2.61,10.41,2.7-10.46-.14-2h5.58l-1,2L98,94.75l.22,1.87h-5.9l-2.46-9.4-2.46,9.4H81.51Z"/>
          <path className="cls-1"
                d="M126.24,95.71l-1.7-1.7a3,3,0,0,1-.91-2.19V84.19a3.06,3.06,0,0,1,.91-2.19l1.7-1.69a3,3,0,0,1,2.19-.91h5.68a3,3,0,0,1,2.19.91L138,82a3.06,3.06,0,0,1,.91,2.19v7.63A3,3,0,0,1,138,94l-1.7,1.7a3,3,0,0,1-2.19.91h-5.68A3,3,0,0,1,126.24,95.71ZM132,93.05a1.87,1.87,0,0,0,1.26-.47l.66-.61a1.67,1.67,0,0,0,.59-1.21V85.25a1.65,1.65,0,0,0-.59-1.2l-.66-.62A1.78,1.78,0,0,0,132,83h-1.42a1.86,1.86,0,0,0-1.26.47l-.66.62a1.74,1.74,0,0,0-.6,1.2v5.51a1.69,1.69,0,0,0,.6,1.21l.66.61a2,2,0,0,0,1.26.47Z"/>
          <path className="cls-1"
                d="M163.57,95.71l-1.7-1.7a3,3,0,0,1-.91-2.19V84.19a3.06,3.06,0,0,1,.91-2.19l1.7-1.69a3,3,0,0,1,2.19-.91h5.68a3,3,0,0,1,2.19.91l1.7,1.69a3.06,3.06,0,0,1,.91,2.19v7.63a3,3,0,0,1-.91,2.19l-1.7,1.7a3,3,0,0,1-2.19.91h-5.68A3,3,0,0,1,163.57,95.71Zm5.73-2.66a1.84,1.84,0,0,0,1.25-.47l.67-.61a1.67,1.67,0,0,0,.59-1.21V85.25a1.65,1.65,0,0,0-.59-1.2l-.67-.62A1.75,1.75,0,0,0,169.3,83h-1.43a1.84,1.84,0,0,0-1.25.47l-.67.62a1.77,1.77,0,0,0-.59,1.2v5.51A1.71,1.71,0,0,0,166,92l.67.61a1.94,1.94,0,0,0,1.25.47Z"/>
          <path className="cls-1"
                d="M213.57,84.19v7.63a3.06,3.06,0,0,1-.92,2.19L211,95.71a3.06,3.06,0,0,1-2.19.91H198.38l.45-2V81.36l-.45-2h10.39a3.06,3.06,0,0,1,2.19.91L212.65,82A3.07,3.07,0,0,1,213.57,84.19Zm-4.43,1.06a1.66,1.66,0,0,0-.6-1.2l-.66-.62a1.75,1.75,0,0,0-1.25-.47h-3.37V93.05h3.37a1.84,1.84,0,0,0,1.25-.47l.66-.61a1.69,1.69,0,0,0,.6-1.21Z"/>
          <path className="cls-1"
                d="M132.9,142.51l1.35-.32v4.11L132.9,146h-5.42v4.36l.45,2h-5.34l.44-2V137.05l-.44-2H135l.73,4.19-1.84-.5h-6.4v3.74Z"/>
          <path className="cls-1"
                d="M156.71,148.34l2.34.47h5.83a1,1,0,0,0,.72-.22l.44-.34a.84.84,0,0,0,.22-.6v-.41c0-.32-.39-.74-.88-.91l-6-2a3.76,3.76,0,0,1-2.53-3.32v-1.6a2.93,2.93,0,0,1,.91-2.19l1.38-1.21a3.6,3.6,0,0,1,2.26-.91h8l.64,4-2.34-.46h-4.4a1.84,1.84,0,0,0-1.13.24l-.5.37a.77.77,0,0,0-.29.62v.27a1.29,1.29,0,0,0,1,1l6.1,2a3.59,3.59,0,0,1,2.36,3.25V148a2.82,2.82,0,0,1-.91,2.22l-1.38,1.2a3.55,3.55,0,0,1-2.26.92h-8.68Z"/>
          <path className="cls-1"
                d="M70.89,206.12l-3.64-13.38-1-2h5.58l-.12,2,2.68,10.46,2.66-10.41-.49-2h4.94l-.51,2,2.6,10.41,2.71-10.46-.15-2h5.59l-1,2-3.65,13.38L87.4,208H81.49L79,198.59,76.57,208H70.69Z"/>
          <path className="cls-1"
                d="M111.49,207.08l-1.7-1.7a3,3,0,0,1-.91-2.18v-7.63a3,3,0,0,1,.91-2.19l1.7-1.7a3,3,0,0,1,2.19-.91h5.68a3,3,0,0,1,2.19.91l1.7,1.7a3,3,0,0,1,.91,2.19v7.63a3,3,0,0,1-.91,2.18l-1.7,1.7a3,3,0,0,1-2.19.91h-5.68A3,3,0,0,1,111.49,207.08Zm5.73-2.66a1.93,1.93,0,0,0,1.26-.46l.66-.62a1.65,1.65,0,0,0,.59-1.2v-5.51a1.67,1.67,0,0,0-.59-1.21l-.66-.61a1.79,1.79,0,0,0-1.26-.47h-1.43a1.84,1.84,0,0,0-1.25.47l-.67.61a1.79,1.79,0,0,0-.59,1.21v5.51a1.69,1.69,0,0,0,.59,1.2l.67.62a2,2,0,0,0,1.25.46Z"/>
          <path className="cls-1"
                d="M159.44,208h-5.61l-.44-2-1.57-3.35h-3.1V206l.46,2h-5.33l.44-2V192.74l-.44-2h10a3.37,3.37,0,0,1,2.19.91l1.7,1.48a2.79,2.79,0,0,1,.91,2.19v2.73a3.06,3.06,0,0,1-.91,2.19l-1.65,1.42,2,4.31Zm-10.72-8.73h3.05a2,2,0,0,0,1.35-.57l.66-.64a1.61,1.61,0,0,0,.42-1.08v-.47a1.49,1.49,0,0,0-.42-1.05l-.66-.74a1.73,1.73,0,0,0-1.35-.57h-3.05Z"/>
          <path className="cls-1"
                d="M194.65,208h-6l-.13-2-2.85-4.89-2.73,3.57V206l.44,2H178l.45-2V192.74l-.45-2h5.34l-.44,2v6.52l4.94-6.5.55-2h6.17l-2.14,2.39-3.81,4.74,4.62,7.95Z"/>
          <path className="cls-1"
                d="M211.88,204l2.34.47h5.83a1,1,0,0,0,.71-.22l.44-.35a.82.82,0,0,0,.23-.59v-.42c0-.32-.4-.73-.89-.91l-6-2a3.77,3.77,0,0,1-2.54-3.32v-1.6a2.92,2.92,0,0,1,.91-2.19l1.38-1.21a3.55,3.55,0,0,1,2.27-.91h8l.64,4-2.34-.47h-4.41a1.86,1.86,0,0,0-1.13.25l-.49.37a.74.74,0,0,0-.3.61v.28a1.28,1.28,0,0,0,1,1l6.1,2a3.59,3.59,0,0,1,2.36,3.24v1.65a2.85,2.85,0,0,1-.91,2.22l-1.38,1.2a3.54,3.54,0,0,1-2.26.91h-8.69Z"/>
          <path className="cls-1"
                d="M51.58,26.3l1.12-.82,1.59,3.79-1.37.23-5,2.09,1.68,4,1.17,1.65-4.93,2.06-.35-2L40.37,25.07,39.2,23.43l11.44-4.79,2.29,3.57L51,22.47l-5.9,2.47,1.45,3.45Z"/>
          <path className="cls-1"
                d="M91,24.16l-.35-1.94-.92-1.55-6,1.49L83.6,24l.59,1.88-5.47,1.35L79.25,25l.86-14-.64-1.77,7.93-2,.24,1.87,7.31,12,1.47,1.66Zm-3-6.52-3.74-6.38-.33,7.38Z"/>
          <path className="cls-1"
                d="M134.55,17.87l-5.59.48-.61-2-1.86-3.2-3.08.27.29,3.36.63,1.92-5.32.46.27-2L118.14,4,117.53,2l9.93-.85a3.33,3.33,0,0,1,2.26.72l1.82,1.32a2.82,2.82,0,0,1,1.1,2.11l.23,2.72a3.06,3.06,0,0,1-.72,2.26l-1.52,1.56L133,16Zm-11.44-7.79,3-.26a1.92,1.92,0,0,0,1.3-.68l.61-.69a1.62,1.62,0,0,0,.33-1.12l0-.46a1.57,1.57,0,0,0-.51-1l-.73-.68a1.76,1.76,0,0,0-1.39-.45l-3,.26Z"/>
          <path className="cls-1"
                d="M161.55,16.15,158.2,2.5l-.84-2,5.67.4-.26,1.95,2.5,11.54,4.07-11.08v-2l5.7.39-1.12,1.9-5.2,13.05.09,1.88L161.2,18Z"/>
          <path className="cls-1"
                d="M210.81,21.54,209,25.21l-12.53-2.83.87-1.82,2.93-13v-2l12.1,2.73-.16,4-1.7-.88-6.26-1.42-.75,3.34,5.3,1.2,1.39,0-.87,3.84L208,17.81l-5.3-1.2L202,20.05l6.49,1.46Z"/>
          <path className="cls-1"
                d="M246.57,37.94l-5.22-2,.32-2-.25-3.68-2.88-1.14-1.23,3.14-.28,2-5-1.95,1.13-1.67L238,18.21l.3-2,9.28,3.64a3.39,3.39,0,0,1,1.71,1.65l1,2a2.8,2.8,0,0,1,0,2.37l-1,2.54a3.08,3.08,0,0,1-1.65,1.71l-2.06.73.33,4.75Zm-6.79-12L242.62,27a2,2,0,0,0,1.47,0l.85-.35a1.58,1.58,0,0,0,.78-.85l.17-.44a1.5,1.5,0,0,0,0-1.14l-.35-.93a1.74,1.74,0,0,0-1-1l-2.84-1.11Z"/>
          <path className="cls-1"
                d="M26.06,260.9l1.84,1.52L33,265.17a1,1,0,0,0,.73.14l.55-.09a.85.85,0,0,0,.48-.42l.19-.36a1.24,1.24,0,0,0-.35-1.23l-4.33-4.61a3.76,3.76,0,0,1-.67-4.12l.76-1.41a2.94,2.94,0,0,1,1.83-1.51l1.79-.41a3.6,3.6,0,0,1,2.42.27l7,3.77L42.16,259l-1.84-1.52-3.88-2.08a2,2,0,0,0-1.12-.32l-.6.1a.72.72,0,0,0-.55.4l-.13.24a1.26,1.26,0,0,0,.37,1.34l4.44,4.64a3.6,3.6,0,0,1,.54,4l-.78,1.45a2.76,2.76,0,0,1-1.84,1.52l-1.79.42a3.63,3.63,0,0,1-2.43-.27l-7.65-4.1Z"/>
          <path className="cls-1"
                d="M70.76,280a3,3,0,0,1-1.61,1.74l-2.18,1a3,3,0,0,1-2.36.1L59.46,281a3,3,0,0,1-1.75-1.61l-1-2.18a3,3,0,0,1-.1-2.37l2.62-7.16a3,3,0,0,1,1.61-1.74l2.18-1a3,3,0,0,1,2.37-.1l5.15,1.89a3,3,0,0,1,1.74,1.61l1,2.18a3,3,0,0,1,.1,2.37l-.23.64-4.42-.75.16-.44a1.53,1.53,0,0,0-.14-1.34l-.39-.77a2,2,0,0,0-1-.88l-1.18-.43a1.78,1.78,0,0,0-1.34,0l-.84.35a1.71,1.71,0,0,0-1,.93l-1.9,5.17a1.61,1.61,0,0,0,.14,1.34l.42.81a2,2,0,0,0,1,.87l1.18.43a2,2,0,0,0,1.36,0l.81-.33a1.52,1.52,0,0,0,1-.93l.17-.46L71,279.36Z"/>
          <path className="cls-1"
                d="M104.47,291.4l0,2-5.23-1.09.84-1.83,1-4.65-6.24-1.31-1,4.65.06,2-5.23-1.09.83-1.83,2.72-13,0-2,5.23,1.09-.86,1.83-1,4.8,6.24,1.3,1-4.79,0-2,5.23,1.09-.86,1.83Z"/>
          <path className="cls-1"
                d="M136.52,296.82l.24-2-.42-1.75-6.16-.36-.62,1.69v2l-5.62-.33,1.14-1.9,5-13.11-.08-1.88,8.15.48-.33,1.85,3.41,13.61.92,2Zm-1-7.13-1.67-7.19-2.5,6.95Z"/>
          <path className="cls-1"
                d="M175.2,291.63l-.65,4.06-12.8,1,.28-2L161,281.49l-.6-1.93,12.36-1,1.06,3.91-1.88-.34-6.4.52.28,3.41,5.41-.44,1.33-.43.32,3.92-1.38-.21-5.42.45.29,3.5,6.62-.54Z"/>
          <path className="cls-1"
                d="M203.05,280.82l1.26-.6.87,4-1.39,0-5.28,1.16.92,4.25.86,1.83-5.22,1.14v-2l-2.83-13-.85-1.82,12.11-2.65,1.61,3.93-1.91-.09L197,278.32l.79,3.66Z"/>
          <path className="cls-1"
                d="M238.71,275.69l.44,4.08-12.07,4.4-.26-2-4.55-12.49-1.09-1.7,11.66-4.24,2.06,3.49-1.91.17-6,2.2,1.17,3.21,5.11-1.86,1.16-.76,1.35,3.69-1.38.17-5.11,1.86,1.2,3.31,6.25-2.28Z"/>
          <path className="cls-1"
                d="M271.92,264,267,266.65l-1.33-1.55-3-2.21-2.74,1.46,1.59,3,1.34,1.52-4.71,2.51-.54-2-6.25-11.72-1.31-1.53,8.79-4.69a3.36,3.36,0,0,1,2.36-.22l2.19.5a2.83,2.83,0,0,1,1.84,1.51l1.28,2.41a3.08,3.08,0,0,1,.23,2.36l-.79,2,3.83,2.84Zm-13.57-2.66,2.69-1.44a2,2,0,0,0,.93-1.13l.28-.88a1.58,1.58,0,0,0-.14-1.15l-.22-.41a1.48,1.48,0,0,0-.86-.74l-.94-.34a1.74,1.74,0,0,0-1.46.14l-2.69,1.43Z"/>
          <path className="cls-1"
                d="M14.71,90.71v1.62a2.68,2.68,0,0,1-.91,2.07l-1.42,1.23a3.35,3.35,0,0,1-2.19.91H0l.44-2V81.28l-.44-2H10.06a2.77,2.77,0,0,1,1.9.79l1.47,1.33a3.2,3.2,0,0,1,.79,2.17V85a2.32,2.32,0,0,1-.84,1.82l-.88.79,1.3,1A2.44,2.44,0,0,1,14.71,90.71ZM4.82,82.54v3.71h3.3A1.18,1.18,0,0,0,9,85.93l.52-.49a.92.92,0,0,0,.3-.69V84a.87.87,0,0,0-.3-.68L9,82.88a1.31,1.31,0,0,0-.91-.34Zm5.49,8.31a1,1,0,0,0-.37-.71l-.52-.57a1.2,1.2,0,0,0-.88-.32H4.82v4H8.54a1.23,1.23,0,0,0,.81-.32l.54-.49a1.09,1.09,0,0,0,.42-.76Z"/>
          <path className="cls-1"
                d="M14.74,139.8v7.63a3.06,3.06,0,0,1-.91,2.19l-1.7,1.69a3,3,0,0,1-2.19.91H-.44l.44-2V137l-.44-2H9.94a3,3,0,0,1,2.19.91l1.7,1.7A3,3,0,0,1,14.74,139.8Zm-4.43,1.06a1.67,1.67,0,0,0-.59-1.21L9.05,139a1.75,1.75,0,0,0-1.25-.47H4.43v10.09H7.8a1.89,1.89,0,0,0,1.25-.47l.67-.62a1.65,1.65,0,0,0,.59-1.2Z"/>
          <path className="cls-1"
                d="M15.5,207.91H9.89l-.44-2-1.58-3.35H4.77v3.37l.47,2H-.1l.44-2V192.66l-.44-2h10a3.39,3.39,0,0,1,2.19.91l1.69,1.48a2.78,2.78,0,0,1,.91,2.18V198a3.06,3.06,0,0,1-.91,2.19l-1.65,1.42,2.05,4.31ZM4.77,199.18H7.82a2,2,0,0,0,1.36-.57l.66-.64a1.61,1.61,0,0,0,.42-1.08v-.47a1.51,1.51,0,0,0-.42-1.06l-.66-.74a1.77,1.77,0,0,0-1.36-.56H4.77Z"/>
          <path className="cls-1"
                d="M297.59,91.74a3,3,0,0,1-.91,2.19l-1.7,1.7a3,3,0,0,1-2.19.91h-5.48a3,3,0,0,1-2.19-.91l-1.7-1.7a3,3,0,0,1-.91-2.19V84.11a3.06,3.06,0,0,1,.91-2.19l1.7-1.69a3,3,0,0,1,2.19-.92h5.48a3,3,0,0,1,2.19.92l1.7,1.69a3.06,3.06,0,0,1,.91,2.19v.69l-4.4.81v-.46a1.53,1.53,0,0,0-.59-1.21l-.64-.59a2,2,0,0,0-1.28-.47h-1.26a1.8,1.8,0,0,0-1.25.47l-.67.61a1.73,1.73,0,0,0-.59,1.21v5.51a1.62,1.62,0,0,0,.59,1.21l.67.61a1.9,1.9,0,0,0,1.25.47h1.26A2,2,0,0,0,292,92.5l.64-.59a1.52,1.52,0,0,0,.59-1.2v-.5l4.4.86Z"/>
          <path className="cls-1"
                d="M284.76,151.31l-1.7-1.69a3.06,3.06,0,0,1-.91-2.19V139.8a3,3,0,0,1,.91-2.19l1.7-1.7A3,3,0,0,1,287,135h5.68a3,3,0,0,1,2.19.91l1.7,1.7a3,3,0,0,1,.91,2.19v7.63a3.06,3.06,0,0,1-.91,2.19l-1.7,1.69a3,3,0,0,1-2.19.91H287A3,3,0,0,1,284.76,151.31Zm5.73-2.65a1.92,1.92,0,0,0,1.26-.47l.66-.62a1.65,1.65,0,0,0,.59-1.2v-5.51a1.67,1.67,0,0,0-.59-1.21l-.66-.61a1.78,1.78,0,0,0-1.26-.47h-1.42a1.86,1.86,0,0,0-1.26.47l-.66.61a1.75,1.75,0,0,0-.59,1.21v5.51a1.65,1.65,0,0,0,.59,1.2l.66.62a2,2,0,0,0,1.26.47Z"/>
          <path className="cls-1"
                d="M296.49,203.75l-1,4.16H284.36l.44-2V192.66l-.44-2h5.33l-.46,1.94v11.61h4.94Z"/>
          <polygon className="cls-1"
                   points="143.47 83.19 143.2 83.19 143.2 83.19 143.47 83.19 143.47 83.19"/>
          <polygon className="cls-1"
                   points="146.76 83.19 146.76 83.19 147.24 83.19 147.24 83.19 146.76 83.19"/>
          <polygon className="cls-1"
                   points="143.47 83.19 143.47 83.19 146.76 83.19 146.76 83.19 143.47 83.19"/>
          <rect className="cls-2" x="83.14" y="141.73" width="3.57"
                height="3.57"/>
          <rect className="cls-2" x="211.57" y="141.73" width="3.57"
                height="3.57"/>
      </SvgIcon>
    )
}

export default StampIcon
