import React from 'react'
import SectionHeader from '../SectionHeader'
import styles from '../../styles/Testimonials.module.css'
import { Carousel } from 'react-responsive-carousel'
import CarouselIndicator from './CarouselIndicator'
import { SanityHomePage } from 'graphql-types'
import CarouselArrow from '../svg/CarouselArrow'
import classNames from 'classnames'
import { Hidden } from '@material-ui/core'
import CarouselArrowShort from '../svg/CarouselArrowShort'
import TestimonialControlArrow
  from './TestimonialsControlArrow'

const BlockContent = require('@sanity/block-content-to-react')

interface Props {
  testimonials: SanityHomePage['_rawTestimonials']
}

const Testimonials = ({ testimonials }: Props): JSX.Element => {
  return (
    <div className={ styles.container }>
      <SectionHeader center dark
                     className={ styles.header }>Testimonials</SectionHeader>
      <Carousel
        showArrows={ false }
        showStatus={ false }
        swipeable
        showThumbs={ false }
        emulateTouch
        infiniteLoop
        className={ styles.carousel }
        stopOnHover
        renderArrowPrev={
          (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void) => (
            <div onClick={ clickHandler }
                 className={ classNames(styles.arrow, styles.backArrow) }>
              <TestimonialControlArrow/>
            </div>
          )
        }
        renderArrowNext={
          (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void) => (
            <div onClick={ clickHandler }
                 className={ classNames(styles.arrow, styles.nextArrow) }>
              <TestimonialControlArrow/>
            </div>
          )
        }
        renderIndicator={
          (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean) => (
            <CarouselIndicator handleClick={ clickHandler }
                               isSelected={ isSelected }/>
          )
        }
      >
        {
          testimonials?.map((testimonial): JSX.Element | null => {
            if (!testimonial) return null
            return (
              <div key={ testimonial?.attribution }
                   className={ styles.testimonial }>
                <BlockContent blocks={ testimonial.quote }/>
                <p
                  className={ styles.attribution }>— { testimonial.attribution }</p>
              </div>
            )
          })
        }
      </Carousel>
    </div>
  )
}

export default Testimonials
