import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  className?: string;
}

const HammerTime = ({className}: Props): JSX.Element => {
  return (
    <SvgIcon width="859.37" height="858.16" viewBox="0 0 859.37 858.16"
             fill="none" className={ className }>
      <defs>
        <style>
          {
            ".cls-1{fill:#8b6730;}"
          }
        </style>
      </defs>

      <path className="cls-1"
            d="M157.42,96.5l55,57.55-9.16,8.75L179,137.43l-29,27.7,24.23,25.37-9.08,8.67-55-57.55,9.08-8.67,23,24.07,29-27.7-23-24.08Z"/>
      <path className="cls-1"
            d="M229.71,50.09l9.78-5,64.11,56.63-11.78,6L274.5,92.28l-27.35,14,2.43,23-11.88,6.06Zm38.37,35L241.17,60.47l3.75,36.46Z"/>
      <path className="cls-1"
            d="M399.25,80.57l-9.38-56.5L373.65,70.73l-6.86,1.14L336.25,33l9.38,56.5-12.38,2L320.22,13l13.27-2.2L367.43,54.5l18-52.3L398.72,0l13,78.5Z"/>
      <path className="cls-1"
            d="M516.6,90.19l9.89-56.4L495.71,72.42l-6.85-1.2L473,24.39l-9.9,56.41L450.7,78.63,464.45.26l13.24,2.32L495.23,55l34.35-43.36L542.82,14,529.07,92.38Z"/>
      <path className="cls-1"
            d="M627.46,120.19l-3.11,10.65L572,115.57l22.27-76.39,51.43,15-3.11,10.65L603.16,53.34l-6.33,21.74L630.94,85,628,95l-34.11-9.94-6.8,23.35Z"/>
      <path className="cls-1"
            d="M647.16,155.11l54.27-58.19,25.33,23.62a22.06,22.06,0,0,1,5.72,8.4,25.49,25.49,0,0,1,1.68,9.62,27.78,27.78,0,0,1-1.92,9.54,25.58,25.58,0,0,1-5.11,8.19,26.94,26.94,0,0,1-6.09,4.89,27.55,27.55,0,0,1-7,2.86,22.26,22.26,0,0,1-7.31.61,20.57,20.57,0,0,1-7-1.86l-7,34.89L682.4,188l6.26-32-13.44-12.53-18.88,20.24Zm35.63-19.8,16.06,15a10.06,10.06,0,0,0,4.37,2.47,11.48,11.48,0,0,0,5,.18,16.56,16.56,0,0,0,5.05-1.8,17.88,17.88,0,0,0,4.56-3.57,15.57,15.57,0,0,0,3.12-4.9,16.5,16.5,0,0,0,1.16-5.36,13.32,13.32,0,0,0-.77-5.08,10.29,10.29,0,0,0-2.69-4.11L703,113.59Z"/>
      <path className="cls-1"
            d="M826.18,295.61l-10.36-24.34-63,26.82-5-11.65,63-26.82-10.4-24.44,10.2-4.34,25.73,60.42Z"/>
      <path className="cls-1"
            d="M770.45,355.41,848.51,340,851,352.3l-78.06,15.43Z"/>
      <path className="cls-1"
            d="M776.48,474.39l57.19,2.93-42.1-25.83.35-6.94L836.45,423l-57.19-2.93.64-12.53,79.47,4.07-.69,13.43-49.92,23.81L856,477.68l-.68,13.43L775.83,487Z"/>
      <path className="cls-1"
            d="M757.47,580.34l-10.42-3.81,18.73-51.26,74.74,27.32L822.13,602.9l-10.43-3.81,14.09-38.52-21.27-7.77-12.19,33.36-9.79-3.57,12.19-33.37-22.84-8.35Z"/>
      <path className="cls-1"
            d="M703.07,760.5l-55.21-57.3,9.12-8.79,24.34,25.26,28.89-27.84-24.34-25.26,9-8.71,55.22,57.3-9,8.71-23.1-24-28.89,27.84,23.1,24Z"/>
      <path className="cls-1"
            d="M631,807.24l-9.76,5-64.37-56.33,11.76-6.06L586,765.25l27.29-14.08-2.54-23L622.63,722Zm-38.53-34.86,27,24.52-3.92-36.44Z"/>
      <path className="cls-1"
            d="M461.3,777.55,470.93,834l16-46.73,6.85-1.17,30.71,38.76-9.63-56.45,12.37-2.11,13.39,78.43L527.38,847l-34.14-43.51-17.78,52.38-13.26,2.26-13.39-78.44Z"/>
      <path className="cls-1"
            d="M343.9,768.47l-9.64,56.45,30.61-38.78,6.85,1.17,16.12,46.75,9.64-56.45,12.37,2.11-13.39,78.44-13.25-2.26-17.78-52.38L331.28,847,318,844.77l13.39-78.44Z"/>
      <path className="cls-1"
            d="M240.94,737.14l5-9.9,48.72,24.59-35.85,71L211,798.73l5-9.91L252.6,807.3l10.2-20.21-31.71-16,4.7-9.31,31.71,16,11-21.71Z"/>
      <path className="cls-1"
            d="M217.07,707.26l-53.14,59.22-25.78-23.13a22.08,22.08,0,0,1-5.88-8.29,25.48,25.48,0,0,1-1.87-9.58,27.59,27.59,0,0,1,1.75-9.58,25.44,25.44,0,0,1,4.95-8.29,26.26,26.26,0,0,1,6-5,27.14,27.14,0,0,1,6.91-3,21.94,21.94,0,0,1,7.3-.75,20.11,20.11,0,0,1,7,1.72l6.29-35,10.59,9.51-5.65,32.12,13.68,12.28,18.49-20.6Zm-35.24,20.48-16.35-14.68a10,10,0,0,0-4.41-2.37,11.55,11.55,0,0,0-5-.09,16.25,16.25,0,0,0-5,1.89,18.23,18.23,0,0,0-4.49,3.66,15.57,15.57,0,0,0-3,5,16.48,16.48,0,0,0-1.06,5.38,13.14,13.14,0,0,0,.87,5.07,10.3,10.3,0,0,0,2.76,4.06L162,749.84Z"/>
      <path className="cls-1"
            d="M35.4,570.24l10.82,24.13,62.48-28,5.18,11.55-62.48,28,10.87,24.23L52.15,634.7,25.27,574.78Z"/>
      <path className="cls-1" d="M90,509.36l-77.75,16.9L9.54,514,87.3,497.09Z"/>
      <path className="cls-1"
            d="M81.67,390.53l-57.24-1.84,42.6,25-.23,6.94L22.69,443l57.24,1.83-.4,12.55L0,454.85l.43-13.44,49.46-24.77L2.12,388.76l.43-13.44,79.53,2.55Z"/>
      <path className="cls-1"
            d="M98.64,284.22l10.49,3.61L91.39,339.44,16.14,313.56l17.42-50.65,10.49,3.6L30.71,305.3l21.41,7.36,11.55-33.59,9.86,3.39L62,316.05,85,324Z"/>
    </SvgIcon>
  )
}

export default HammerTime
