import React from 'react'
import { SanityHomePage, SanityValueProposition } from '../../../graphql-types'
import styles from '../../styles/ValuePropositions.module.css'
import { Image } from 'rebass'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../SectionHeader'
import StampIcon from '../svg/StampIcon'
import { Hidden } from '@material-ui/core'

const BlockContent = require('@sanity/block-content-to-react')

export interface Props {
  valuePropositions?: (Pick<SanityValueProposition, 'title' | 'icon'> | null)[] | null;
  _rawValuePropositions: SanityHomePage['_rawValuePropositions'];
}

const serializers = {
  container: (props) => (
    <h4>{ props.children }</h4>
  ),
}

const ValuePropositions = ({ valuePropositions, _rawValuePropositions }: Props) => {
  if (!valuePropositions) return null
  return (
    <section className={ styles.container }>
      <SectionHeader className={ styles.header } center>Our
        Values</SectionHeader>
      <div className={ styles.grid }>
        <Hidden xsDown implementation='css'>
          <StampIcon className={ styles.stamp }/>
        </Hidden>
        <Grid container spacing={ 3 }>
          {
            valuePropositions.map((record, i: number) => {
              const { title, icon } = record
              return (
                <Grid item className={ styles.gridItem } sm={ 3 } xs={ 6 }
                      key={ title }>
                  <Image src={ icon?.asset?.url || '' }
                         className={ styles.icon }/>
                  <BlockContent blocks={ _rawValuePropositions[i]?.title }
                                renderContainerOnSingleChild
                                className={ styles.title }/>
                  <div className={ styles.body }>
                    <BlockContent blocks={ _rawValuePropositions[i]?.body }/>
                  </div>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </section>
  )
}

export default ValuePropositions
