import React from 'react'
import { SanityCarouselImages } from '../../../graphql-types'
import 'react-responsive-carousel/lib/styles/carousel.css'
import styles from '../../styles/WorkCarousel.module.css'
import CarouselIndicator from './CarouselIndicator'
const Carousel = require('react-responsive-carousel').Carousel

export interface Props {
  images?: (Pick<SanityCarouselImages, 'image' | 'name'> | null)[] | null;
}

const WorkCarousel = ({images}: Props) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      autoPlay
      swipeable
      showThumbs={false}
      emulateTouch
      infiniteLoop
      className={styles.carousel}
      stopOnHover
      renderIndicator={
        (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean) =>(
          <CarouselIndicator handleClick={clickHandler} isSelected={isSelected} />
          )
      }
    >
      {
        images?.map((record) => {
          if (!record?.name) return null
          return (
            <div key={record?.name} style={{backgroundImage: `url(${record?.image?.asset?.url})`}} className={styles.image} />
          )
        })
      }
    </Carousel>
  )
}

export default WorkCarousel
